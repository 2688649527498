import React, { useEffect } from "react";
import Footer1 from "../../layout/footer/footer1";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import "../searchpage/search.css";
import searchno from "../../../images/no reslts found 1.png";
import { Link, withRouter } from "react-router-dom";

function Search(props) {
  console.log(props);
  const setInputsfun = (id, tittle1) => {
    props.history.push(`/webinar/${id}/${tittle1}`);
    props?.setSearchKey("");
  };
  const setInputscourse = (id, tittle1) => {
    props.history.push(`/courses-details/${id}/${tittle1}`);
    props?.setSearchKey("");
  };

  const partnerfil = props?.data?.course?.filter(item=>item?.course_type==="Free")
  console.warn(partnerfil);
  return (
    <div className="Search-div-item-main" style={{ backgroundColor: "white",minHeight:"100vh" }}>
      <h3
        className="search-title"
        style={{ color: "#007A78", fontFamily: "montserrat" }}
      >
        Search results for <a>“{props.searchKey}”</a>
      </h3>

      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div sm={5}>
          <Nav variant="pills" className="flex" id="searchvariant">
            <Nav.Item className="searchall">
              <Nav.Link className="searchpills" eventKey="first">
                All
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="searchall ">
              <Nav.Link className="searchpills" eventKey="second">
                Courses
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="searchall">
              <Nav.Link className="searchpills" eventKey="third">
                Webinars
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <hr
          style={{
            border: "-0.98px solid #c4c4c4",
            width: "88%",
            marginBottom: "0",
          }}
        ></hr>
        <br />
        <Tab.Content id="search-scrolls" className="search-height">
          <Tab.Pane eventKey="first">
            <div className="searchscroll text-capitalize">
              {props?.data?.length === 0 ? (
                <div>
                  <h4 className="no-result-found-msg">No Results Found</h4>
                  <img className="no-res-img" src={searchno} alt="" />
                </div>
              ) : (
                <div>
                  {props.data?.webinar?.map((post) => {
                    // let tittle1 = post.title.replace(/[[&#,+()$~%.``^'"'!':*?<> {}]/g, '-').split("]").join("-");
                    // const title2 = post.title.replace(/[[&#,+()$~%.``^'"'!':*?<> {}-]+/ig, '-').split(']').join("-");
                    // const tittle1 = title2.replace(/[^\x00-\x7F]/g, "");
                    let title2 = post.title.replace(/[^\x00-\x7F]+/gi, "");
                    let tittle1 = title2.replace(
                      /[\]\[@&#,+()$~%.``^'"'!':*?<> {}-]+/gi,
                      "-"
                    );
                    return (
                      <div onClick={() => setInputsfun(post.id, tittle1)}>
                        <div className="searchcrd-w">
                          <div className="searchCard d-flex  ">
                            <div className="imgsearch">
                              <img
                                className="search-crd"
                                src={post.image}
                                alt="IMAGE"
                                style={{ maxWidth: "none" }}
                              />
                            </div>
                            <div
                              className="textsearch my-auto"
                              style={{
                                marginLeft: "35px",
                                fontFamily: "catamaran",
                              }}
                            >
                              <span
                                style={{
                                  color: "#33AA9D",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                webinar
                              </span>
                              <h5
                                className="search-crd-title"
                                style={{
                                  color: "#007A78",
                                  fontFamily: "Montserrat",
                                  fontWeight: "700",
                                }}
                              >
                                {post.title}
                              </h5>
                              <small className="sr-insr-title mt-2">
                                {post?.instructor}
                              </small>
                              <div className="d-flex mt-md-3">
                                {/* <div>
                        {post?.duration/60}Hrs
                     </div> */}
                                <div>
                                  <small
                                    className={
                                      post?.webinar_type === "Free"
                                        ? "search-type-free"
                                        : "search-type-premimum"
                                    }
                                  >
                                    {post?.webinar_type === "Free"
                                      ? ""
                                      : `₹ ${post?.webinar_type} `}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr style={{ border: "-0.98px solid #c4c4c4" }}></hr>
                        </div>
                      </div>
                    );
                  })}
                  {props.data?.course?.map((post) => {
                    //  const title1 = post.title.split(" ").join("-")
                    // const title1 = post.title.replace(/[[&#,+()$~%.``^'"'!':*?<> {}]/g, '-').split("]").join("-");
                    const title2 = post.title.replace(/[^\x00-\x7F]+/gi, "");
                    const title1 = title2.replace(
                      /[\]\[@&#,+()$~%.``^'"'!':*?<> {}-]+/gi,
                      "-"
                    );
                    return (
                      <div onClick={() => setInputscourse(post.id, title1)}>
                        <div className="searchcrd-w ">
                          <div className="searchCard d-flex">
                            <div className="imgsearch">
                              <img
                                className="search-crd"
                                src={post.image}
                                alt="..."
                                style={{ maxWidth: "none" }}
                              />
                            </div>
                            <div
                              className="textsearch my-auto"
                              style={{
                                marginLeft: "35px",
                                fontFamily: "catamaran",
                              }}
                            >
                             { post?.course_type ==="partner"?<span style={{ color: "#33AA9D" }}>Partner Course</span>:<span style={{ color: "#33AA9D" }}>Course</span>}
                              <h5
                                className="search-crd-title"
                                style={{
                                  color: "#007A78",
                                  fontFamily: "Montserrat",
                                  fontWeight: "600",
                                }}
                              >
                                {post.title}
                              </h5>
                              <small className="sr-insr-title">
                                {post?.instructor}
                              </small>
                              <div className="d-flex mt-md-3">
                                {/* <div>
                     {post?.duration/60}Hrs 
                     </div> */}
                                <div>
                                  <small
                                    className={
                                      post?.course_type === "Free"
                                        ? "search-type-free"
                                        : "search-type-premimum"
                                    }
                                  >
                                    {post?.course_type === "Free"
                                      ? ""
                                      : `₹${post?.course_type}`}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr style={{ border: "-0.98px solid #c4c4c4" }}></hr>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            {props.data?.course?.length === 0 || props?.data?.length === 0 || partnerfil?.length === 0? (
              <div>
                <h4 className="no-result-found-msg">No Results Found</h4>
                <img className="no-res-img" src={searchno} alt="" />
              </div>
            ) : (
              props.data?.course?.map((post) => {
                // let title1 = post.title.split(" ").join("-")
                //  const title1 = post.title.replace(/[[&#,+()$~%.``^'"'!':*?<> {}]/g, '-').split("]").join("-");
                const title2 = post.title.replace(/[^\x00-\x7F]+/gi, "");
                const title1 = title2.replace(
                  /[\]\[@&#,+()$~%.``^'"'!':*?<> {}-]+/gi,
                  "-"
                );
                return (

                  <Link to={`/courses-details/${post.id}/${title1}`}>
                   {post?.course_type === "partner"?"":<div className="searchcrd-w">
                      <div className="searchCard d-flex">
                        <div className="imgsearch">
                          <img
                            className="search-crd"
                            src={post.image}
                            alt="..."
                            style={{ maxWidth: "none" }}
                          />
                        </div>
                        <div
                          className="textsearch my-auto"
                          style={{
                            marginLeft: "35px",
                            fontFamily: "catamaran",
                          }}
                        >
                        { post?.course_type ==="partner"?<span style={{ color: "#33AA9D" }}>Partner Course</span>:<span style={{ color: "#33AA9D" }}>Course</span>}
                          <h5
                            className="search-crd-title"
                            style={{
                              color: "#007A78",
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                            }}
                          >
                            {post.title}
                          </h5>
                          <small className="sr-insr-title">
                            {post?.instructor}
                          </small>
                          <div className="d-flex mt-md-3">
                            {/* <div>
                     {post?.duration/60}Hrs 
                     </div> */}
                            <div>
                              <small
                                className={
                                  post?.course_type === "Free"
                                    ? "search-type-free"
                                    : "search-type-premimum"
                                }
                              >
                                {post?.course_type === "Free"
                                  ? ""
                                  : `₹ ${post?.course_type} `}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ border: "-0.98px solid #c4c4c4" }}></hr>
                    </div>}
                  </Link>
                );
              })
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="third">
            {props.data?.webinar?.length === 0 || props?.data?.length === 0 ? (
              <div>
                <h4 className="no-result-found-msg">No Results Found</h4>
                <img className="no-res-img" src={searchno} alt="" />
              </div>
            ) : (
              props.data?.webinar?.map((post) => {
                // const tittle1 = post.title.replace(/[[&#,+()$~%.``^'"'!':*?<> {}]/g, '-').split("]").join("-");
                const title2 = post.title.replace(/[^\x00-\x7F]+/gi, "");
                const tittle1 = title2.replace(
                  /[\]\[@&#,+()$~%.``^'"'!':*?<> {}-]+/gi,
                  "-"
                );
                // let tittle1 = post.title.split(" ").join("-");
                return (
                  <Link to={`/webinar/${post.id}/${tittle1}`}>
                    <div className="searchcrd-w">
                      <div className="searchCard d-flex">
                        <div className="imgsearch">
                          <img
                            className="search-crd"
                            src={post.image}
                            alt="IMAGE"
                            style={{ maxWidth: "none" }}
                          />
                        </div>
                        <div
                          className="textsearch my-auto"
                          style={{
                            marginLeft: "35px",
                            fontFamily: "catamaran",
                          }}
                        >
                          <span
                            className="text-capitalize"
                            style={{ color: "#33AA9D" }}
                          >
                            webinar
                          </span>
                          <h5
                            className="search-crd-title"
                            style={{
                              color: "#007A78",
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                            }}
                          >
                            {post.title}
                          </h5>
                          <small className="sr-insr-title">
                            {post?.instructor}
                          </small>
                          <div className="d-flex mt-md-3">
                            {/* <div>
                        {post?.duration/60}Hrs
                     </div> */}
                            <div>
                              <small
                                className={
                                  post?.webinar_type === "Free"
                                    ? "search-type-free"
                                    : "search-type-premimum"
                                }
                              >
                                {post?.webinar_type !== "Free" && "₹"}{" "}
                                {post?.webinar_type === "Free"
                                  ? ""
                                  : `₹ ${post?.webinar_type} `}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ border: "-0.98px solid #c4c4c4" }}></hr>
                    </div>
                  </Link>
                );
              })
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}
export default withRouter(Search);
