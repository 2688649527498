// import  Menu  from 'react-burger-menu/lib/menus/stack'
import { slide as Menu } from "react-burger-menu";
import { Link, useHistory } from "react-router-dom";
import "./header.css";
import { getExploreDatas, getuserprofile } from "../../../api/fetchClient";
import closeBtn from "../../../images/icon/close.svg";
import rigthArrow from "../../../images/chevronright.png";
import leftArrow from "../../../images/chevronleft.png";
import UserIcon from "../../../images/icon/userProfile.png";
import editIcon from "../../../admin/images/icons/edit icon 1.png";
import headcell from "../../../images/icon/phone-call.svg";
import crown from "../../../images/crown 2.png";
import Editprofile from "../../../images/icon/edit profile.svg";
import FreeBtn from "./FreeBtn";

import { useState, useEffect } from "react";

function SideMenu({
  show,
  onMobExpClick,
  plandetails,
  aff,
  onHamburgerClick,
  onUpFreeBtnlink,
  topNotiBanner,
  blogCatList1,
  blogCatList2,
  blogCatList3,
  blogCatList4,
}) {
  const [picture, setPicture] = useState();
  const [explore, setExplore] = useState();
  // console.log("explore", explore);
  const userString = localStorage.getItem("user_data");
  const user = JSON.parse(userString);
  const [showProfileDrop, setShowProfileDrop] = useState(false);
  const [showPrograms, setShowPrograms] = useState(false);
  const [showBlog, setShowBlog] = useState(false);
  console.log(user);
  let history = useHistory();
  const logout = () => {
    window.localStorage.clear();
    history.push("/");
    window.location.reload();
  };

  const courselink = () => {
    window.scrollTo(0, 0);
    history.push("/courses/?page=1");
    onHamburgerClick();
  };

  const webinarlink = () => {
    window.scrollTo(0, 0);
    history.push("/webinar/?page=1");
    onHamburgerClick();
  };

  const learnMapLink = () => {
    window.scrollTo(0, 0);
    history.push("/learnmap");
    onHamburgerClick();
  };
  const profProlink = () => {
    window.scrollTo(0, 0);
    history.push("/professional-programs");
    onHamburgerClick();
  };

  const onMobMyProfileClick = () => {
    setShowProfileDrop(!showProfileDrop);
  };
  const onMobProgramsClick = () => {
    setShowPrograms(!showPrograms);
  };
  const onMobBlogClick = () => {
    setShowBlog(!showBlog);
  };

  useEffect(() => {
    const getData = async () => {
      const getExp = await getExploreDatas().then((resp) => {
        setExplore(resp);
      });
    };
    getData();
  }, []);

  const [showMyProfile, setShowMyProfile] = useState(false);

  // console.log(show);

  useEffect(() => {
    const getdata = async () => {
      const get = await getuserprofile().then((resp) => {
        // console.log(resp);
        setPicture(resp);
      });
    };
    if (user?.access_token) {
      getdata();
    }
  }, []);

  const freeBtnFun = () => {
    return plandetails?.plan !== "Free" && user?.access_token ? (
      ""
    ) : (
      <li className="header-nav-hov">
        <Link to="/subscribe">
          <div className="subscribe-bac">
            <div>
              {plandetails?.is_freetrial_used === true
                ? "Upgrade Plan"
                : "Try For Free "}
            </div>
          </div>
        </Link>
      </li>
    );
  };

  const FREE_BTN = freeBtnFun();
  return (
    // <Menu right customBurgerIcon={<img src={burgetIcon} alt="icon" />}>
    <div className="side-bar-open-overlay">
      <div className="side-navbar">
        <div>
          <img
            onClick={() => {
              onHamburgerClick();
              setShowProfileDrop(false);
              setShowPrograms(false);
            }}
            className="close-side-bar-icon"
            src={closeBtn}
          />
        </div>
        {user?.access_token ? (
          <div className="sidebar-profile-section">
            {picture?.profile_pic === null ? (
              <img src={UserIcon} alt="user" />
            ) : (
              <img src={picture?.profile_pic} alt="User_profile" />
            )}
            {/* <h2>{picture?.full_name}</h2> */}
            <h4>{picture?.email}</h4>
          </div>
        ) : (
          ""
        )}

        {/* <div className="subscribe-bac-mob">
          <li className="header-nav-hov">
            <Link to="/subscribe">
              <div className="subscribe-bac-mob">
                <img src={crown} alt="premium icon" />
                <div>Subscribe</div>
              </div>
            </Link>
          </li>
        </div> */}

        {user?.access_token && <FreeBtn onUpFreeBtnlink={onUpFreeBtnlink} onHamburgerClick={onHamburgerClick} />}
        {user?.user?.user_type === "admin" ? (
          <Link to="/admin">
            <li>
              {" "}
              <a>Admin</a>
            </li>
          </Link>
        ) : (
          ""
        )}
        {user?.user?.user_type === "partner" ? (
          <Link to="/partner">
            <li>
              {" "}
              <a>Partner</a>
            </li>
          </Link>
        ) : (
          ""
        )}
        {aff?.is_verified === "accepted" &&
        user?.user?.user_type === "affiliate" ? (
          <Link
            to={
              aff?.is_tc_accepted === false
                ? "/affiliates"
                : "/affiliates/dashboard"
            }
          >
            <li>
              {" "}
              <a>Affiliate</a>
            </li>
          </Link>
        ) : (
          ""
        )}
        {user?.access_token ? (
          <>
            <div onClick={onMobMyProfileClick}>
              <li className="d-flex justify-content-between mobile-drop-li">
                My Profile
                <img
                  className="mob-drop-down-icon"
                  src={`${showProfileDrop ? leftArrow : rigthArrow}`}
                ></img>
              </li>
            </div>
            {showProfileDrop && (
              <div className={`pop-up-mob ${topNotiBanner ? "mtp-ctnt" : ""}`}>
                <img
                  onClick={onMobMyProfileClick}
                  className="mob-drop-down-icon"
                  src={`${showProfileDrop ? leftArrow : rigthArrow}`}
                ></img>
                <span> My Profile</span>
                <div className="exp-popup-col pop-content-wrap">
                  {user?.access_token ? (
                    <Link onClick={() => onHamburgerClick()} to="/edit-profile">
                      {/* <div className="edit-text-mob">
                    <li className="mt-3">
                      <div className="edit-txt-img-sec">
                        <div>Edit Profile</div>
                        <img src={editIcon} alt="edit icon" />
                      </div>
                    </li>
                  </div> */}
                      <li>
                        <a>Edit Profile</a>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}
                  {user?.access_token ? (
                    <Link
                      onClick={() => onHamburgerClick()}
                      to="/mylearning/compeleted"
                    >
                      <li>
                        <a>My Certificates</a>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
{/* 
        {user?.access_token && (
          <Link onClick={() => onHamburgerClick()} to="/mylearning/inprogress">
            <li>My Learning</li>
          </Link>
        )} */}

        {/* <div onClick={onMobExpClick}>
          <li className="d-flex justify-content-between mobile-drop-li">
            Explore
            <img
              className="mob-drop-down-icon"
              src={`${show ? leftArrow : rigthArrow}`}
            ></img>
          </li>
        </div> */}

        {/* {show && (
          <div className={`pop-up-mob ${topNotiBanner ? "mtp-ctnt" : ""}`}>
            <img
              onClick={onMobExpClick}
              className="mob-drop-down-icon"
              src={`${show ? leftArrow : rigthArrow}`}
            ></img>
            <span>Explore</span>
            <div className="exp-popup-col pop-content-wrap">
              <div className="Explore-main-div">
                {explore &&
                  explore.slice(0, 4)?.map((items) => (
                    <ul>
                      <li>
                        <Link
                          onClick={() => onHamburgerClick()}
                          to={`/result/${items.category_type}`}
                        >
                          <a>{items.category_type}</a>
                        </Link>
                      </li>
                    </ul>
                  ))}
              </div>
              <div className="Explore-main-div">
                {explore &&
                  explore.slice(4, 8)?.map((items) => (
                    <ul>
                      <li>
                        <Link
                          onClick={() => onHamburgerClick()}
                          to={`/result/${items.category_type}`}
                        >
                          <a>{items.category_type}</a>
                        </Link>
                      </li>
                    </ul>
                  ))}
              </div>
              <div className="Explore-main-div">
                {explore &&
                  explore.slice(8, 12)?.map((items) => (
                    <ul>
                      <li>
                        <Link
                          onClick={() => onHamburgerClick()}
                          to={`/result/${items.category_type}`}
                        >
                          <a>{items.category_type}</a>
                        </Link>
                      </li>
                    </ul>
                  ))}
              </div>
              <div className="Explore-main-div">
                {explore &&
                  explore.slice(12, 16)?.map((items) => (
                    <ul>
                      <li>
                        <Link
                          onClick={() => onHamburgerClick()}
                          to={`/result/${items.category_type}`}
                        >
                          <a>{items.category_type}</a>
                        </Link>
                      </li>
                    </ul>
                  ))}
              </div>
            </div>
          </div>
        )} */}

        {/* <div onClick={onMobProgramsClick}>
          <li className="d-flex justify-content-between mobile-drop-li">
            Programs
            <img
              className="mob-drop-down-icon"
              src={`${showPrograms ? leftArrow : rigthArrow}`}
            ></img>
          </li>
        </div> */}
        {showPrograms && (
          <div className={`pop-up-mob ${topNotiBanner ? "mtp-ctnt" : ""}`}>
            <img
              onClick={onMobProgramsClick}
              className="mob-drop-down-icon"
              src={`${showPrograms ? leftArrow : rigthArrow}`}
            ></img>
            <span> Programs</span>
            <div className="exp-popup-col pop-content-wrap">
              <div onClick={courselink}>
                <li> Courses</li>
              </div>
              <div onClick={webinarlink}>
                <li> Webinars</li>
              </div>
             
               
              <div onClick={learnMapLink}>
                <Link>
                  <li> LearnMap</li>
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* {user?.access_token ? (
          plandetails?.plan === "Basic" ||
          plandetails?.plan === "Premium" ||
          plandetails?.plan === "Elite" ? (
            <Link onClick={() => onHamburgerClick()} to="/subscribe">
              <li>
                <a>Subscription Plans</a>
              </li>
            </Link>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}
        {/* <Link onClick={() => onHamburgerClick()} to="/subscribe">
          <li className="new-feature-tag-new">Pricing</li>
        </Link> */}

        {/* <Link onClick={() => onHamburgerClick()} to="/resources/ebooks/">
          <li className="new-feature-tag-new">
            Resources
          </li>
        </Link> */}
        {/* <div onClick={onMobBlogClick}>
          <li className="d-flex justify-content-between mobile-drop-li">
            Blog
            <img
              className="mob-drop-down-icon"
              src={`${showPrograms ? leftArrow : rigthArrow}`}
            ></img>
          </li>
        </div> */}

        {showBlog && (
          <div className={`blog-pop-up-mob ${topNotiBanner ? "mtp-ctnt" : ""}`}>
            <img
              onClick={onMobBlogClick}
              className="mob-drop-down-icon"
              src={`${showBlog ? leftArrow : rigthArrow}`}
            ></img>
            <span> Blogs</span>
            <div className="exp-popup-col blog-content-wrap">
              <div className="mob-blog-content">
                <h2>Investing</h2>
                {blogCatList1?.slice(0, 3)?.map((each) => {
                  return (
                    <a href={each?.link}>{each?.yoast_head_json?.title}</a>
                  );
                })}
              </div>
              <div className="mob-blog-content">
                <h2>Trading</h2>
                {blogCatList2?.slice(0, 3)?.map((each) => {
                  return (
                    <a href={each?.link}>{each?.yoast_head_json?.title}</a>
                  );
                })}
              </div>
              <div className="mob-blog-content">
                <h2>Mutual Funds</h2>
                {blogCatList3?.slice(0, 3)?.map((each) => {
                  return (
                    <a href={each?.link}>{each?.yoast_head_json?.title}</a>
                  );
                })}
              </div>
              <div className="mob-blog-content">
                <h2>Stock Market Basics</h2>
                {blogCatList4?.slice(0, 3)?.map((each) => {
                  return (
                    <a href={each?.link}>{each?.yoast_head_json?.title}</a>
                  );
                })}
              </div>
              <a className="vist-blog-mob" href="https://blog.joinfingrad.com/">
                Visit All Blogs
              </a>
            </div>
          </div>
        )}
        <>
          {/* <div className="mt-2 mb-2">
            <a href="https://blog.joinfingrad.com/">Blog</a>
          </div> */}

          {user?.access_token ? (
            ""
          ) : (
            <div className="before-login-sec">
              {/* <p>
                Be a part of the FinGrad student community to learn about
                trading through our well crafted courses and webinars
              </p> */}

              <div style={{width:"100%"}} className="before-login-btn">
                <div
                style={{width:"100%"}}
                  onClick={() =>
                    history.push({
                      pathname: "/login",
                      state: { redirect_url: history.location.pathname },
                    })
                  }
                >
                  <button style={{width:"100%"}} className="before-login-login">Login</button>
                </div>
                {/* <li className="header-nav-hov">
                  <Link onClick={() => onHamburgerClick()} to="/subscribe">
                    <div className="subscribe-bac">
                      <div>Start My Free Trial</div>
                    </div>
                  </Link>
                </li> */}
              </div>
            </div>
          )}
          {user?.access_token ? (
            <Link onClick={() => onHamburgerClick()} to="/cart">
              <li> Cart</li>
            </Link>
          ) : (
            ""
          )}

          {user?.access_token ? (
            <li className="font-weight-bold" onClick={logout}>
              Logout
            </li>
          ) : (
            ""
          )}
        </>
        {/* <Link to="/login"><li>Login</li></Link> */}
      </div>
    </div>

    // </Menu>
  );
}
export default SideMenu;
